/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { NotificationComponent as SourceNotification } from 'SourceComponent/Notification/Notification.component';
import { ReactComponent as AlertErrorIcon } from 'Style/icons/alert-error.svg';
import { ReactComponent as AlertWarningIcon } from 'Style/icons/alert-warn.svg';
import { ReactComponent as CheckIcon } from 'Style/icons/check.svg';
import { ReactComponent as InfoIcon } from 'Style/icons/info.svg';
import { ReactComponent as XIcon } from 'Style/icons/x.svg';

import {
    ERROR_TYPE,
    INFO_TYPE,
    SUCCESS_TYPE,
    WARN_TYPE,
} from './Notification.config';

import './Notification.style';

/**
 * Notification block
 * @class Notification
 * @namespace Satisfly/Component/Notification/Component */
export class NotificationComponent extends SourceNotification {
    renderIcon(type) {
        if (type === SUCCESS_TYPE) {
            return (
                <div block="Notification" elem="InfoIcon">
                    <CheckIcon />
                </div>
            );
        }

        if (type === ERROR_TYPE) {
            return (
                <div block="Notification" elem="InfoIcon">
                    <AlertErrorIcon />
                </div>
            );
        }

        if (type === INFO_TYPE) {
            return (
                <div block="Notification" elem="InfoIcon">
                    <InfoIcon />
                </div>
            );
        }

        if (type === WARN_TYPE) {
            return (
                <div block="Notification" elem="InfoIcon">
                    <AlertWarningIcon />
                </div>
            );
        }

        return null;
    }

    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;
        const type = msgType.toLowerCase();

        const mods = {
            type,
            is: isNotificationVisible ? 'opening' : 'closing',
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                { this.renderIcon(type) }
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>
                    <XIcon />
                </button>
                <p block="Notification" elem="Text">
                    { msgText }
                </p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
